<template>
    <router-view/>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>

<style>

body{
  padding: 0;
  margin: 0;
  background: #1E1E1E;
}
@font-face {
  font-family: "CraftworkGrotesk";
  font-weight: 800;
  src: url('/public/craftwork-grotesk/CraftworkGrotesk-Heavy.ttf') format('truetype');
}

@font-face {
  font-family: "CraftworkGrotesk";
  font-weight: 700;
  src: url('/public/craftwork-grotesk/CraftworkGrotesk-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "CraftworkGrotesk";
  font-weight: 600;
  src: url('/public/craftwork-grotesk/CraftworkGrotesk-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: "CraftworkGrotesk";
  font-weight: 500;
  src: url('/public/craftwork-grotesk/CraftworkGrotesk-Medium.ttf') format('truetype');
}

@font-face {
  font-family: "CraftworkGrotesk";
  font-weight: 400;
  src: url('/public/craftwork-grotesk/CraftworkGrotesk-Regular.ttf') format('truetype');
}


#app {
  font-family: 'CraftworkGrotesk';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

*{
  font-family: 'CraftworkGrotesk';
}

h1, h2, h3, h4, h5, h6, p, a{
  margin: 0;
  padding: 0;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.content{
  width: 90%;
  max-width: 1200px;
}

.hv-100{
  height: 100vh;
}

@media (min-width: 900px) and (max-width: 1200px) {
  .content{
    width: 90%;
    max-width: 900px;
  }
}

@media (min-width: 550px) and (max-width: 900px) {
  .content{
    width: 90%;
  }
}

@media (min-width: 0px) and (max-width: 550px) {
  .content{
    width: 90%;
  }
}


</style>
